import Rainbow from "./Rainbow";

const Header = () => {
  return (
    <header className="h-[400px] w-[100%]">
      <Rainbow index={true}>
        <h1 className="text-white text-4xl md:text-8xl text-center">PRIDE DICTIONARY</h1>
      </Rainbow>
    </header>
  );
};

export default Header;
