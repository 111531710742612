import Card from "./components/Card/Card";
import Footer from "./components/ui/Footer";
import Header from "./components/ui/Header";
import Dictionary from "./components/Dictionary/Dictionary";
import { arr } from "./utils/arr";

import { ChevronDown as Down , ChevronUp as Up , DeleteIcon } from "./components/Icons/Icons";

import { useState } from "react";

function App() {
  let newArr = Object.entries(arr);

  const [filteredArr, setFilteredArr] = useState(newArr);
  const [description, setDescription] = useState(true);

  const resetResults = () => {
    setFilteredArr(newArr);
  };

  const handleDescription = () => {
    setDescription((prev) => prev = !prev)
  }

  const filterArr = (e) => {
    let results = newArr.filter((other) => {
      return other[0]
        .toLocaleLowerCase()
        .includes(e.target.value.toLocaleLowerCase());
    });
    setFilteredArr(results);
  };

  return (
    <div className="App">
      <Header />
      <section className="container mx-auto px-4">
        <Card classes={`transition ease-in-out -mt-4 ${!description ? ' float-right px-[1rem] py-3 -mt-[5rem] shadow-md' : ''}`}>
          <button onClick={handleDescription} className="float-right p-3 bg-[var(--button-bg)] rounded-[15px]">
            {description ? <Up/> : <Down/>}
          </button>
          {description ? (
            <>
              <h2 className="text-[var(--text-main-color)] text-[26px] md:text-[42px] mt-12">
                How does it work?
              </h2>
              <p className="text-[var(--text-main-color)] text-[22px]">
                Just search for the term you’re interested in the search bar.
              </p>
              <h2 className="text-[var(--text-main-color)] text-[26px] md:text-[42px]">
                Where did I get this information?
              </h2>
              <p className="text-[var(--text-main-color)] text-[22px]">
                This website is inspired in <a className="underline"  rel="noreferrer" target="_blank" href="https://rainbowcenter.uconn.edu/wp-content/uploads/sites/2262/2019/01/LGBTQIA-Dictionary-FINAL-Spring-18.pdf">The University of Connecticut
                Rainbow Center’s LGBTQIA+ Dictionary.</a>
              </p>
            </>
          ) : (
            ''
          )}
        </Card>
        <Card classes={`my-[1rem] ${!description ? ' mt-16 ' : ''}`}>
          <form className={`flex items-space-between gap-3`}>
            <input
              onChange={filterArr}
              className="text-[var(--rainbow-blue)] focus:outline-none focus:shadow-outline w-full p-3 rounded-[15px] bg-[var(--button-bg)] text-[22px] placeholder-[var(--placeholder)] "
              placeholder="Search..."
              type="text"
            />
            <button
              onClick={resetResults}
              className="w-14 p-3 bg-[var(--button-bg)] rounded-[15px] text-[var(--rainbow-blue)]"
              type="reset"
            >
              <DeleteIcon/>
            </button>
          </form>
        </Card>
      </section>
      <Dictionary content={filteredArr} />
      <Footer></Footer>
    </div>
  );
}

export default App;
