const Rainbow = (props) => {
  return (
    <div className={`flex flex-col h-[100%] w-100 relative ${props.index ? '-z-50' : ''}`}>
      <div className="absolute h-full w-[100%] flex items-center justify-center">
        {props.children}
      </div>
      <div className="bg-[var(--rainbow-red)] h-[100%] w-[100%]"></div>
      <div className="bg-[var(--rainbow-orange)] h-[100%] w-[100%]"></div>
      <div className="bg-[var(--rainbow-yellow)] h-[100%] w-[100%]"></div>
      <div className="bg-[var(--rainbow-green)] h-[100%] w-[100%]"></div>
      <div className="bg-[var(--rainbow-blue)] h-[100%] w-[100%]"></div>
      <div className="bg-[var(--rainbow-purple)] h-[100%] w-[100%]"></div>
    </div>
  );
};

export default Rainbow;
