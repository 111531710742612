import DictionaryCard from "../DictionaryCard/DictionaryCard";

const Dictionary = (props) => {
  return (
    <section className="container mx-auto px-4">
      {props.content.map((item) => (
        <DictionaryCard key={item}>
          <h2 className="text-[32px] text-[var(--rainbow-blue)]">
            {item[0]}:{" "}
          </h2>
          <p className="text-[20px] text-[var(--rainbow-blue)]">{item[1]}</p>
        </DictionaryCard>
      ))}
      {props.content.length === 0 && (
        <DictionaryCard>
          <div className="h-60 flex items-center justify-center">
            <h2 className="text-[32px] text-[var(--rainbow-blue)]">
              No results!
            </h2>
          </div>
        </DictionaryCard>
      )}
    </section>
  );
};

export default Dictionary;
