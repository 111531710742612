import Rainbow from "./Rainbow";

const Footer = () => {
  return (
    <footer className="h-[100px]">
      <Rainbow>
        <div className="flex flex-col items-center justify-center0">
          <h3 className="text-white text-[20px]">
            Developed by: 
            <a className="underline" href="https://danielq.dev">
               danielq.dev
            </a>
          </h3>
          <p className="text-white">Happy pride month!</p>
        </div>
      </Rainbow>
    </footer>
  );
};
export default Footer;
